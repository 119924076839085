<template>
    <div>
        <Header title="Usuários" addText="Usuário" :hasAddBtn="rules.includes('create.auth.api')" :addFields="addFields" :meta="meta" :extraFields="addFieldsToGroup" extraMode="attachToGroup"/>

        <b-table sticky-header="65vh" no-border-collapse hover :items="users" :fields="fields">
            <template #cell(actions)="row">
                <div class="btn-icons">
                    <div v-if="rules.includes('edit.auth.api')"><i @click="$emit('callModal', 'form-modal', 'edit', row.item, addFields, meta, addFieldsToGroup, 'attachToGroup')" class = "material-icons btn-icon">edit</i><span>Editar</span></div>
                    <div v-if="$store.getters.isAdmin"><i @click="$emit('callModal', 'delete-modal', 'edit', row.item, addFields, meta, null, '', /* addFieldsToGroup, 'attachToGroup', */ deleteUser)" class = "material-icons btn-icon">delete</i><span>Deletar</span></div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>

import Header from '@/components/Dashboard/Header'

import api from '@/services/condinvest'
import { toast } from '@/utils'

export default {
  components: {
    Header
  },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  created () {
    api.get('user/index?group_id=' + this.groupId).then((response) => {
      console.log(response.data)
      if (response.status === 200) {
        this.users = response.data.users
      }
    }, error => {
      toast(this, 'danger', 'Erro ao obter users', error.message)
      return Promise.reject(error)
    })
  },
  data: () => ({
    users: [],
    meta: {
      title: 'User',
      module: 'users',
      state: 'users',
      modes: {
        add: {
          title: 'Cadastrar novo Usuário',
          success: 'Usuário cadastrado com successo',
          urn: 'auth/create',
          response: 'user',
          method: 'post'
        },
        edit: {
          title: 'Editar Usuário',
          success: 'Usuário editado com successo',
          urn: 'auth/edit',
          response: 'user',
          method: 'post'
        },
        attachToGroup: {
          title: 'Adicionar Usuário a um Grupo',
          success: 'Usuário linkado com sucesso ao grupo',
          urn: 'group/linkuser',
          response: 'group',
          method: 'post'
        }
      }
    },
    fields: [
      { key: 'name', label: 'Nome', sortable: true },
      { key: 'groups', label: 'Grupo', formatter: (value, key, item) => value.reduce((str, v) => str + v.key + ', ', '').slice(0, -2) },
      { key: 'actions', label: 'Ações', sortable: true }
    ],
    addFields: [
      { key: 'name', label: 'Nome', type: 'text' },
      { key: 'email', label: 'Email', type: 'text' },
      { key: 'password', label: 'Senha', type: 'password', validator: self => self.$store.getters.isAdmin || self.mode === 'add' },
      { key: 'password_confirmation', label: 'Confirmar Senha', type: 'password', validator: self => self.$store.getters.isAdmin || self.mode === 'add' },
      { key: 'telephone', label: 'Telefone', type: 'text', mask: '(##) #####-####' },
      { key: 'cpf', label: 'CPF', type: 'text', mask: ['###.###.###-##', '##.###.###/####-##'] }
    ],
    addFieldsToGroup: [
      // { key: 'user_id', type: 'extra', value: 'id' }, add manually
      { key: 'group_id', label: 'Grupo', type: 'select', module: 'groups', state: 'groups', options: { value: 'id', text: 'key' } }
    ]
  }),
  computed: {
    rules () {
      return this.$store.state.rules.map(a => a.key)
    }
  },
  methods: {
    deleteUser (user) {
      api.delete('user/delete?feedback=web&user_id=' + user.id).then((response) => {
        console.log(response.data)
        if (response.status >= 200 || response.status < 300) {
          this.$router.go()
        }
      }, error => {
        toast(this, 'danger', 'Erro ao deletar user', error.message)
        return Promise.reject(error)
      })
    }
  }
}
</script>
